import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import { TimezonesService } from './timezones.service';
import { Developer } from '@model';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class DeveloperService {
  private _developers: Developer[] = [];
  get developers(): Developer[] {
    return this._developers;
  }

  private readonly developersEmitter = new Subject<Developer[]>();
  readonly developers$ = this.developersEmitter.asObservable();

  private readonly searchFilterEmitter = new Subject<string>();
  readonly searchFilter$ = this.searchFilterEmitter.asObservable();

  private readonly selectDeveloperInListEmitter = new Subject<Developer>();
  readonly selectDeveloperInList$ =
    this.selectDeveloperInListEmitter.asObservable();

  constructor(
    private resourceService: ResourceService<Developer>,
    private timezoneService: TimezonesService,
    private alertService: GlobalAlertService,
  ) {}

  getDevelopers$(): Observable<Developer[]> {
    return fromPromise<Developer[]>(this.resourceService.getTree(['developer','account','site']) as Promise<Developer[]>).pipe(
      tap(developers => {
        this.developersEmitter.next(developers);
        this._developers = [...developers];
      }),
      catchError((error: unknown) => {
        this.alertService.setError(error.toString());
        this.developersEmitter.error(error);
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  getDeveloper$(developerId: number | string): Observable<Developer> {
    return this.resourceService.getById('developers', developerId).pipe(
      tap(developer => {
        const developerIndex = this._developers.findIndex(c => c.id === developer.id);
        if (developerIndex === -1) {
          this._developers.unshift(developer);
        } else {
          this._developers[developerIndex] = developer;
        }
        this.timezoneService.setTimezone(developer.id, developer.timezone);
      }),
    );
  }

  sendValue(value: string): void {
    this.searchFilterEmitter.next(value);
  }

  selectDeveloperInList(developer: Developer): void {
    this.selectDeveloperInListEmitter.next(developer);
  }
}
