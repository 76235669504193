import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import { ProfileJson } from '@model';
import { CookieService, TimezonesService } from '@service';

@Component({
  selector: 'deros-dashboard-demand-profile-chart',
  templateUrl: './demand-profile-chart.component.html',
  styleUrls: ['./demand-profile-chart.component.scss'],
})
export class DemandProfileChartComponent implements OnChanges, AfterViewInit {
  @Input() siteId: string;
  @Input() demandProfileJsons: ProfileJson[];

  constructor(
    private cookieService: CookieService,
    private timezoneService: TimezonesService,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['demandProfileJsons'].isFirstChange()) {
      this.setOptions();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setOptions(), 100);
    // this.setOptions();
  }

  private setOptions(): void {
    const data = this.demandProfileJsons.map(p => [
      +new Date(p.timestamp),
      p.value,
    ]);
    const tooltipFormatter = this.tooltipFormatter;
    Highcharts.chart('chart-container', {
      chart: {
        type: 'line',
        style: {
          fontFamily: 'var(--fonts)',
        },
      },
      mapNavigation: {
        enableMouseWheelZoom: true,
      },
      title: {
        text: '',
        align: 'left',
        margin: 25,
        style: {
          color: '#333333',
          fontSize: '14px',
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: this.translateService.instant('chart.demand'),
          textAlign: 'center',
          // style: { color: "purple", fontSize: "15px" }
        },
        visible: true,
        startOnTick: false,
        endOnTick: false,
        maxPadding: 0,
        minPadding: 0,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          autoRotation: [-45],
          style: {
            color: '#999999',
            fontSize: '12px',
          },
          formatter: this.xAxisFormatter,
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
          },
          turboThreshold: 0,
        },
      },
      legend: {
        itemStyle: {
          color: '#666666',
          fontSize: '12px',
        },
      },
      series: [
        {
          type: 'line',
          showInLegend: false,
          data: data,
          color: 'var(--primary-color)',
        },
      ],
      tooltip: {
        shared: true,
        useHTML: true,
        shadow: false,
        borderColor: '#BBBBBB',
        formatter: function (): string {
          return tooltipFormatter(this.x as string, this.y);
        },
      },
    });
  }

  xAxisFormatter = (data: AxisLabelsFormatterContextObject): string => {
    return this.timezoneService.getDateTimeDisplayLabel(
      this.siteId,
      data.value as string,
    );
  };

  tooltipFormatter = (x: string, y: unknown): string => {
    const dateHtml = this.timezoneService.getDateTimeDisplayLabel(
      this.siteId,
      x,
    );
    const containerStyle = 'padding: 1rem; width: 330px';
    const dateStyle = 'font-size: 11px; margin-bottom: 2rem; display: block';
    const h2Style =
      'color: var(--primary-color); margin-right: 0.5rem; display: inline-block;';
    const kwStyle = 'color: var(--primary-color); display: inline-block;';
    return `<div style="${containerStyle}"><span style="${dateStyle}">${dateHtml}</span><h2 style="${h2Style}">${this.translateService.instant(
      'chart.demand',
    )}:</h2><h6 style="${kwStyle}">${y}</h6></div>`;
  };
}
