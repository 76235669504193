import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { Account } from '@model';

@Injectable()
export class AccountService {
  private readonly accountEmitter = new Subject<Account[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<Account>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getAccounts$(developerId: number | string): Observable<Account[]> {
    return this.resourceService.getList(`developers/${developerId}/accounts`);
  }

  getAllAccounts$(): Observable<Account[]> {
    return this.resourceService.getList(`types/account`).pipe(
      tap(accounts => {
        this.accountEmitter.next(accounts);
      }),
    );
  }

  getAccount$(accountId: number | string): Observable<Account> {
    return this.resourceService.getById('accounts', accountId);
  }
}
