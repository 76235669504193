<div class="container">
  <div *ngIf="sites?.length" data-automation="site-table-container">
    <table
            data-automation="site-table"
            mat-table
            matSort
            [dataSource]="dataSource"
            (matSortChange)="sortData($event)">

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
          {{ 'account.overview.site_availability' | translate }}
        </th>
        <td mat-cell *matCellDef="let site" data-automation="site-table-status">
          <ng-container [ngSwitch]="site['status']">
            <ng-container *ngSwitchCase="'PENDING'">
              <img src="../../../../../assets/images/oval-yellow.svg.svg" class="images" alt="yellow">
            </ng-container>
            <ng-container *ngSwitchCase="'ACTIVE'">
              <img src="../../../../../assets/images/oval-green.svg" class="images" alt="green">
            </ng-container>
            <ng-container *ngSwitchCase="'DEACTIVATED'">
              <img src="../../../../../assets/images/oval-red.svg" class="images" alt="red">
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
          {{ 'account.overview.site_name' | translate }}
        </th>
        <td mat-cell *matCellDef="let site" data-automation="site-table-name">
          {{ site.displayLabel }}
        </td>
      </ng-container>

      <ng-container matColumnDef="control-mode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="controlMode">
          {{ 'account.overview.control_mode' | translate }}
        </th>
        <td mat-cell *matCellDef="let site" data-automation="site-table-control-mode">
          {{ site.controlMode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="der-status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="derStatus">
          {{ 'account.overview.der_status' | translate }}
        </th>
        <td mat-cell *matCellDef="let site" data-automation="site-table-der-status">
          {{ site.derStatus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="timezone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="timezone">
          {{ 'account.overview.site_timezone' | translate }}
        </th>
        <td mat-cell *matCellDef="let site" data-automation="site-table-time-zone">
          {{ site.timezone }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let site; columns: displayedColumns;"
          id="{{'site-table-' + site.id}}"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>
