<div class='dialog-container' data-automation="deros-dashboard-dialog">
  <deros-dashboard-loader></deros-dashboard-loader>
  <div class='text-center'>
    <h1 mat-dialog-title data-automation='dialog-title'>{{ data.title }}</h1>
    <div mat-dialog-content>
      <p data-automation='dialog-content-text'>{{ data.contentText }}</p>
    </div>
    <div mat-dialog-actions class='flex items-center justify-evenly'>

      <button
        *ngIf='data.secondButtonText'
        class='icon-btn primary-btn'
        data-automation='dialog-second-button'
        matRipple
        (click)='data.secondButtonCallback()'
      >
        <span> {{ data.secondButtonText }}</span>
      </button>

      <button
        class='icon-btn primary-btn first-button'
        data-automation='dialog-first-button'
        matRipple
        (click)='data.firstButtonCallback()'
      >
        <span> {{ data.firstButtonText }}</span>
      </button>
    </div>
  </div>
</div>
