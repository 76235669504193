import { Location } from './location.model';
import { Site } from './site.model';
import { Username } from './username.model';

export interface Account {
  id: string;
  parentId: string;
  displayLabel: string;
  displayLabels: Record<string, string>;
  description: string;
  descriptions: Record<string, string>;
  timezone: string;
  locale: string;
  children: Site[];
  createdBy: Username;
  createdDate: string;
  lastUpdatedDate: string;
  settings: Location;
  spaceType: string;
}

export class Account {
  constructor() {
    this.id = '';
    this.parentId = '';
    this.displayLabel = '';
    this.displayLabels = {} as Record<string, string>;
    this.description = '';
    this.descriptions = {} as Record<string, string>;
    this.timezone = '';
    this.locale = '';
    this.children = new Array<Site>();
    this.createdBy = {} as Username;
    this.createdDate = '';
    this.lastUpdatedDate = '';
    this.settings = new Location();
    this.spaceType = 'Account';
  }
}
