import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { Site } from '@model';

@Injectable()
export class SiteService {
  private readonly siteEmitter = new Subject<Site[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<Site>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getSites$(accountId: number | string): Observable<Site[]> {
    return this.resourceService.getList(`accounts/${accountId}/sites`);
  }

  getAllSites$(): Observable<Site[]> {
    return this.resourceService.getList(`types/site`).pipe(
      tap((sites) => {
        this.siteEmitter.next(sites);
      }),
    );
  }

  getSite$(siteId: number | string): Observable<Site> {
    return this.resourceService.getById('sites', siteId);
  }
}
