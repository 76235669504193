import { Configuration, Location, Username } from '@model';

export interface Site {
  id: string;
  parentId: string;
  displayLabel: string;
  displayLabels: Record<string, string>;
  description: string;
  descriptions: Record<string, string>;
  status: string;
  timezone: string;
  locale: string;
  createdBy: Username;
  createdDate: string;
  lastUpdatedDate: string;
  settings: Location;
  configurations: Configuration[];
  controlMode: string;
  derStatus: string;
  spaceType: string;
}

export class Site {
  constructor() {
    this.id = '';
    this.parentId = '';
    this.displayLabel = '';
    this.displayLabels = {};
    this.description = '';
    this.descriptions = {};
    this.status = '';
    this.timezone = '';
    this.locale = '';
    this.createdBy = {} as Username;
    this.createdDate = '';
    this.lastUpdatedDate = '';
    this.settings = new Location();
    this.configurations = new Array<Configuration>();
    this.controlMode = '';
    this.derStatus = '';
    this.spaceType = 'Site';
  }
}
