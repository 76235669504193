import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class DashboardService {
  fakeCall$(): Observable<string[]> {
    return of([
      'Pre-MVP version scheduled for release in Q1 2023',
      'MVP version scheduled for release in Q2 2023',
    ]);
  }
}
