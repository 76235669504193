import { Account } from './account.model';
import { Location } from './location.model';
import { Username } from './username.model';

export interface Developer {
  id: string;
  parentId: string;
  displayLabel: string;
  displayLabels: Record<string, string>;
  description: string;
  descriptions: Record<string, string>;
  timezone: string;
  locale: string;
  children: Account[];
  createdBy: Username;
  createdDate: string;
  lastUpdatedDate: string;
  location: Location;
  spaceType: string;
}

export class Developer {
  constructor() {
    this.id = '';
    this.parentId = '';
    this.displayLabel = '';
    this.displayLabels = {} as Record<string, string>;
    this.description = '';
    this.descriptions = {} as Record<string, string>;
    this.timezone = '';
    this.locale = '';
    this.children = new Array<Account>();
    this.createdBy = {} as Username;
    this.createdDate = '';
    this.lastUpdatedDate = '';
    this.location = new Location();
    this.spaceType = 'Developer';
  }
}
