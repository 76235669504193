import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Site } from '@model';
import { GlobalAlertService, SiteService } from '@service';

@Injectable()
// eslint-disable-next-line prettier/prettier
export class SiteResolver {
  constructor(
    private siteService: SiteService,
    private globalAlertService: GlobalAlertService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Site> {
    const siteId =
      route.queryParamMap.get('siteId') || route.paramMap.get('siteId');

    return this.siteService.getSite$(siteId).pipe(
      catchError((err: unknown) => {
        this.globalAlertService.setError(err.toString());
        return throwError(() => new Error(err.toString()));
      }),
    );
  }
}
