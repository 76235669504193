import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Account, Site } from '@model';
import { AccountService, SiteService } from '@service';

@Component({
  selector: 'deros-dashboard-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  subscriptions = [];
  account = new Account();
  sites: Site[] = [];

  constructor(
    private accountService: AccountService,
    private siteService: SiteService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const paramSubscription = this.route.data.subscribe((data: Account) => {
      console.log('data: ', data);
      this.account = data['data'];
    });

    this.subscriptions.push(paramSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
