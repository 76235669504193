import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '@service';

@Component({
  selector: 'deros-dashboard-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(
    public loaderService: LoaderService,
    private readonly self: ElementRef<LoaderComponent>,
  ) {}

  ngOnDestroy(): void {
    this.loaderService.unregisterLoader(this.self);
  }

  ngOnInit(): void {
    this.loaderService.registerLoader(this.self);
  }
}
