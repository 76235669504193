import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LatestUpdateService } from '@service';
import { LatestUpdate } from '@model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'deros-dashboard',
  templateUrl: './deros-dashboard.component.html',
  styleUrls: ['./deros-dashboard.component.scss'],
})
export class DerosDashboardComponent implements OnInit {
  subscriptions = [];
  updates: string[];

  latestUpdates: LatestUpdate[];

  constructor(
    private route: ActivatedRoute,
    private updateService: LatestUpdateService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.updateService.setUpdates().subscribe(data => {
        this.latestUpdates = data;
      }),
    );
  }

  goTo(url: string): void {
    window.open(url, '_blank');
  }
}
