import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guard';
import { PermissionEnum } from '@model';
import { AccountResolver, DashboardResolver, SiteResolver } from '@resolver';
import {
  AccountComponent,
  AccountModule,
  DerosDashboardComponent,
  DerosDashboardModule,
  SiteComponent,
  SiteModule,
} from '@view';

enum Routing {
  doc = 'doc',
  list = 'list',
  configured = 'configured',
  history = 'history',
  root = '',
  viewAccount = 'details/:accountId/view-account',
  viewSite = 'details/:siteId/view-site',
}

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: 'reload',
    enableTracing: false
};

const routes: Routes = [
  {
    path: Routing.viewAccount,
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read] },
    resolve: { data: AccountResolver },
  },
  {
    path: Routing.viewSite,
    component: SiteComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.update] },
    resolve: { data: SiteResolver },
  },
  {
    path: Routing.doc,
    redirectTo: '/doc/index.html',
  },
  {
    path: Routing.root,
    component: DerosDashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read] },
    resolve: { updates: DashboardResolver },
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    AccountModule,
    DerosDashboardModule,
    RouterModule.forRoot(routes, routerOptions),
    SiteModule,
  ],
  exports: [RouterModule],
  providers: [AuthGuard, DashboardResolver, AccountResolver, SiteResolver],
})
export class CoreRoutingModule {}
