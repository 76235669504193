export interface Location {
  address: string;
  latitude: number;
  longitude: number;
}

export class Location {
  constructor();
  constructor(address: string, lat: number, lng: number);
  constructor(...args: any[]) {
    if (!args?.length) {
      this.address = null;
      this.latitude = null;
      this.longitude = null;
    }
    if (args?.length === 3) {
      this.address = args[0];
      this.latitude = args[1];
      this.longitude = args[2];
    }
  }
}
