import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Configuration } from '@model';
import { TimezonesService } from '@service';

@Component({
  selector: 'deros-dashboard-der-table',
  templateUrl: './der-table.component.html',
  styleUrls: ['./der-table.component.scss'],
})
export class DerTableComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() configurations: Configuration[];
  dataSource: MatTableDataSource<Configuration>;
  displayedColumns = [
    'status',
    'name',
    'updatedBy',
    'lastUpdatedDate',
    'actions',
  ];

  constructor(public timezoneService: TimezonesService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.configurations = this.configurations.sort(
      (firstDate: Configuration, secondDate: Configuration) => {
        return (
          new Date(secondDate.lastUpdatedDate).valueOf() -
          new Date(firstDate.lastUpdatedDate).valueOf()
        );
      },
    );
    this.initDataSource(this.configurations);
  }

  sortData(sort: Sort): void {
    let data = this.configurations.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(this.configurations);
      return;
    }

    data = data.sort(
      (configuration1: Configuration, configuration2: Configuration) => {
        const isAscending = sort.direction === 'asc';
        switch (sort.active) {
          case 'type':
            return this.compare(
              configuration1.type ?? '',
              configuration2.type,
              isAscending,
            );
          case 'name':
            return this.compare(
              configuration1.displayLabel,
              configuration2.displayLabel,
              isAscending,
            );
          case 'oem':
            return this.compare(
              configuration1.oem,
              configuration2.oem,
              isAscending,
            );
          case 'rackBatteries':
            return this.compare(
              configuration1.rack,
              configuration2.rack,
              isAscending,
            );
          case 'powerCapacity':
            return this.compare(
              configuration1.powerCapacity,
              configuration2.powerCapacity,
              isAscending,
            );
          case 'energyCapacity':
            return this.compare(
              configuration1.energyCapacity,
              configuration2.energyCapacity,
              isAscending,
            );
          case 'lastUpdatedDate':
            return this.compareDate(
              configuration1.lastUpdatedDate,
              configuration2.lastUpdatedDate,
              isAscending,
            );
          default:
            return 0;
        }
      },
    );

    this.initDataSource(data);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a: string, b: string, isAsc: boolean): number {
    const aa = new Date(a);
    const bb = new Date(b);
    return (aa < bb ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private initDataSource(configurations: Configuration[]): void {
    if (!configurations?.length) {
      return;
    }
    const initTable = (): void => {
      this.dataSource = new MatTableDataSource<Configuration>(configurations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    };
    setTimeout(initTable);
  }
}
