import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { UploaderService } from './uploader.service';
import { GlobalAlertService } from '@service';

@Component({
  selector: 'deros-dashboard-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent<T> implements OnChanges {
  @Input() title: string;
  @Input() fileLabel: string;
  @Input() multiple = false;
  @Input() url: string;
  @Input() createMethod: 'post' | 'put';
  @Input() accept: string;
  @Input() formDataParameters: Map<string, string>;
  @Input() object: T | T[];
  @Output() objectChange = new EventEmitter<T | T[]>();
  @Input() idField: string;
  @Input() labelField: string;
  @ViewChild('file') file: ElementRef;
  @ViewChild('uploader') uploader: ElementRef;
  showFiles = false;
  showError = false;
  showLoader = false;
  files: Set<File> = new Set();
  uploading = false;
  progress: { [p: string]: { progress: Observable<number> } };

  constructor(
    private globalAlertService: GlobalAlertService,
    public uploadService: UploaderService<T>,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.checkForExist();
  }

  addFiles(): void {
    this.renderer.addClass(this.uploader.nativeElement, 'uploader-drag');
    setTimeout(
      () =>
        this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag'),
      200,
    );
    this.file.nativeElement.click();
  }

  onFilesAdded(droppedFiles?: FileList): void {
    let files: { [key: string]: File };
    if (droppedFiles) {
      const filesArray = Array.from(droppedFiles).filter(f =>
        this.validateFile(f),
      );
      const wrongFiles = Array.from(droppedFiles).filter(
        val => !filesArray.includes(val),
      );
      if (wrongFiles.length) {
        const errorMessage = `${this.translateService.instant(
          'uploader.files_not_accepted',
        )}: ${wrongFiles.map(f => f.name).join(' ,')}.`;
        this.globalAlertService.setError(errorMessage);
      }
      files = filesArray as unknown as { [key: string]: File };
    } else {
      files = this.file.nativeElement.files;
    }
    if (this.multiple) {
      this.uploadService.object = [];
    } else {
      this.uploadService.object = null;
    }
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
    this.uploadFile();
  }

  uploadFile(): void {
    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.progress = this.uploadService.upload(
      this.files,
      this.url,
      this.createMethod,
      this.formDataParameters,
    );

    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    // When all progress-observables are completed...
    // eslint-disable-next-line deprecation/deprecation
    forkJoin(allProgressObservables).subscribe({
      next: () => {
        this.uploading = false;
        this.showLoader = true;
        this.object = this.uploadService.object;
        this.objectChange.emit(this.object);
      },
      error: (err: unknown) => {
        this.globalAlertService.setError(err.toString());
        this.clear();
      },
      // () => (this.showError = true),
    });
  }

  /**
   * Clear file input and checks for files.
   */
  clear(): void {
    this.showError = false;
    this.showLoader = false;
    this.files = new Set();
    this.file.nativeElement.value = '';
  }

  remove(id: string): void {
    if (this.multiple) {
      this.object = (<T[]>this.object).map(o => {
        if (o[this.idField] === id) {
          return null;
        }
        return o;
      });
      this.object = this.object.filter(o => o);
      if (!this.object.length) {
        this.clear();
        this.showFiles = false;
      }
    } else {
      this.clear();
      this.object = null;
      this.showFiles = false;
    }
    this.objectChange.emit(this.object);
  }

  private checkForExist(): void {
    if (!this.object) {
      this.showFiles = false;
    }
    if (this.multiple) {
      this.object = this.object as T[];
      this.object = this.object?.filter(o => o[this.idField]);
      if (this.object?.length) {
        this.showFiles = true;
        this.showLoader = false;
      }
    } else {
      this.object = this.object as T;
      if (this.object && this.object[this.idField]) {
        this.showFiles = true;
        this.showLoader = false;
      }
    }
  }

  dragOver(e: DragEvent): void {
    e.preventDefault();
  }

  dragEnter(e: DragEvent): void {
    e.preventDefault();
    this.renderer.addClass(this.uploader.nativeElement, 'uploader-drag');
    e.dataTransfer.dropEffect = 'copy';
  }

  dragLeave(e: DragEvent): void {
    e.preventDefault();
    this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag');
  }

  filesDrop(e: DragEvent): void {
    e.preventDefault();
    this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag');
    const files = e.dataTransfer.files;
    if (files.length) {
      this.onFilesAdded(files);
    }
  }

  validateFile(file: File): boolean {
    const fileExtension = file.name.split('.').pop();
    return this.accept.includes(fileExtension);
  }
}
