import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DerosDashboardComponent } from './deros-dashboard.component';

@NgModule({
  declarations: [DerosDashboardComponent],
  exports: [DerosDashboardComponent],
  imports: [CommonModule, TranslateModule],
})
export class DerosDashboardModule {}
