import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Site } from '@model';
import { TimezonesService, SiteService } from '@service';

@Component({
  selector: 'deros-dashboard-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.scss'],
})
export class SiteTableComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() sites: Site[];
  dataSource: MatTableDataSource<Site>;
  displayedColumns = [ 
    'timezone',
    'status',
    'name'
  ];

  constructor(public timezoneService: TimezonesService, public siteService: SiteService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.sites = this.sites.sort((firstDate: Site, secondDate: Site) => {
      return (
        new Date(secondDate.lastUpdatedDate).valueOf() -
        new Date(firstDate.lastUpdatedDate).valueOf()
      );
    });
    this.initDataSource(this.sites);
  }

  

  sortData(sort: Sort): void {
    let data = this.sites.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(this.sites);
      return;
    }

    data = data.sort((site1: Site, site2: Site) => {
      const isAscending = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return this.compare(
            site1.status,
            site2.status,
            isAscending,
          );
        case 'name':
          return this.compare(
            site1.displayLabel,
            site2.displayLabel,
            isAscending,
          );
        case 'controlMode':
          return this.compare(
            site1.controlMode,
            site2.controlMode,
            isAscending,
          );
        case 'derStatus':
          return this.compare(
            site1.derStatus,
            site2.derStatus,
            isAscending,
          );
        case 'timezone':
          return this.compare(
            site1.timezone,
            site2.timezone,
            isAscending
            );
        
        default:
          return 0;
      }
    });

    this.initDataSource(data);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private initDataSource(sites: Site[]): void {
    if (!sites?.length) {
      return;
    }
    const initTable = (): void => {
      this.dataSource = new MatTableDataSource<Site>(sites);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    };
    setTimeout(initTable);
  }
}
