export interface Ders {
  id: string;
  ess: {
    id: string;
    qty: number;
  }[];
  totalKw: number;
  totalKwh: number;
}

export class Ders {
  constructor(id: string, qty: number) {
    this.ess = [{ id, qty }];
  }
}
