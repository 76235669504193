import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Site } from '@model';
import { GoogleService, TreeService } from '@service';

@Component({
  selector: 'deros-dashboard-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit, AfterViewInit, OnDestroy {
  mode: 'home' | 'diagnostics' | 'financial' = 'home';
  site: Site;
  apiKey: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private listService: TreeService,
    private googleService: GoogleService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.subscribe(data => {
        const site: Site = data['data'];
        this.listService.selectSiteInList(site);
        this.site = site;
      }),
    );
    this.apiKey = this.googleService.apiKey;
    this.mode = this.route.snapshot.queryParamMap.get('tab') as
      | 'home'
      | 'diagnostics'
      | 'financial';
    setTimeout(() => this.appendModeToQueryParam(this.mode), 1000);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.appendModeToQueryParam(this.mode), 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  changeTab(mode: 'home' | 'diagnostics' | 'financial'): void {
    this.mode = mode;
    this.appendModeToQueryParam(mode);
  }

  private appendModeToQueryParam(
    mode: 'home' | 'diagnostics' | 'financial',
  ): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: mode,
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true,
      // do not trigger navigation
    });
  }
}
